import React, { Component } from "react";

import { graphql } from "gatsby";

import Layout from "../components/Layout";
import { SiteTitle } from "../components/elements/SiteTitle";
import { Footer } from "../components/collections/Footer";

class Index extends Component {
  render() {
    return (
      <Layout>
        <SiteTitle>Page Not Found</SiteTitle>
        <h1>Something went wrong</h1>
        <Footer />
      </Layout>
    );
  }
}

export default Index;
